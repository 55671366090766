import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  number: null,
  buddies: []
});

export default () => ({
  ...initialCrudState(initialModel),
  buddy: {}
});
